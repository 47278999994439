@import '../../../../styles/basics';

.footer-social-links {
  width: 200px;
  display: inline-block;
  margin-bottom: 26px;

  &__list {
    @include list-unstyled();
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &__list-item {
    padding: 0;
    margin: 0;
    font-size: 9px;
  }

  &__icon {
    color: $green-800;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  &__icon-with-dropdown {
    position: relative;
    padding-right: 4px;
    &:after {
      content: "";
      position: absolute;
      right: -4px;
      top: 7px;
      border: 3px solid transparent;
      border-top: 3px solid $green-800;
    }

    &.opened,
    &:hover ,
    &:focus {
      color: $green-1200;
      &:after {
        border-top-color: $green-1200;
      }
    }
  }

  // Specific active/hover styles for youtube icon.
  &__icon-youtube {
    &:active path:last-child,
    &:hover path:last-child {
      fill: $white;
    }
  }

  &__accounts {
    position: absolute;
    z-index: 1;
    left: -4px;
    border: 1px solid $slate-100;
    background: $white;
    border-radius: 8px;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    box-shadow: $shadow-soft;
  }
  &__accounts-item {
    padding: 8px 14px;
    font-size: 12px;
    color: $slate-1200;
    white-space: nowrap;

    &:hover,
    &:active,
    &:focus {
      color: $slate-1200;
      text-decoration: none;
      background-color: $gray-100;
    }
  }

  @include media-breakpoint-up(lg) {
    &__accounts {
      right: -10px;
      left: auto;
    }
  }
}
