@import '../../styles/gifts/sass/basics';

// TopBar
.top-bar {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background: $green-800;
  color: $white;
  padding: 3px 0;
  height: 34px;
  overflow: hidden;
  margin-top: 0;
  z-index: 100;
  font-size: 12px;
  line-height: 28px;
  transition: 0.2s all;

  &--collapsed {
    height: 0;
    padding: 0;
  }

  &__container {
    position: relative;
  }

  &__text-right {
    position: relative;
    right: 0;
    float: none;
    text-align: center;
    padding: 0;
    p {
      display: inline-block;
      margin-bottom: 0;
    }
    a {
      color: $white;
      cursor: pointer;
    }
  }

  &__text-left {
    display: none;
    p {
      display: inline-block;
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    font-size: 16px;
    &__container {
      margin: 0;
    }
    &__text-right {
      display: inline-block;
      position: absolute;
    }
  }

  @include media-breakpoint-up(xl) {
    &__text-left {
      display: inline-block;
    }
  }
}

.test-payment-mode-warning {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 10px;

  a {
    opacity: 0.7;
    font-size: 11px;
    background-color: orangered;
    color: $white;
    text-decoration: none;
    padding: 2px 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: block;
  }
}