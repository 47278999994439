@import '../../styles/gifts/sass/basics';

.remove-product-button {
  color: $gray-800;
  font-size: 14px;
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
    color: $gray-800;
  }
}
