@import '../../styles/gifts/sass/basics';

.free-product-offer {
  color: $pink-1200;
  font-size: 14px;

  &--basket-page {
    padding-bottom: 5px;
  }

  &--basket-widget {
    margin-top: 16px;
    padding: 0 5px;
    p {
      margin: 0;
      padding: 0;
      line-height: 1.4;
    }
  }
}
