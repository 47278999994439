@import '../../../../styles/basics';

header.site-header {
  display: block;
  position: relative;
  z-index: 6500;
  width: 100%;
  background-color: $white;
  padding: 16px 20px 14px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);

  :global {
    .header-container {
      padding-left: 36px;
      padding-right: 36px;
      display: flex;
      justify-content: space-between;
    }
  }

  :global(.logo) {
    height: 30px;
  }

  p {
    margin-bottom: 0;
  }

  &.basic {
    @include media-breakpoint-up(lg) {
      padding: 21px 0 22px;
    }

    :global {
      .header-menu {
        // line-height so home link icon is centered vs the oversized logo
        line-height: 30px;

        @include media-breakpoint-up(lg) {
          line-height: 38px;
        }
      }

      .left {
        display: flex;
        align-items: center;

        .logo {
          margin-right: 16px;

          @include media-breakpoint-up(lg) {
            margin-right: 24px;
            height: 39px;
          }
        }
      }
    }
  }

  &.full {
    padding: 0;

    @include media-breakpoint-up(lg) {
      padding-top: 15px;
      padding-bottom: 8px;
    }

    :global {
      .logo {
        // compensate for not containing padding on mobile and
        // making up the remaining padding for the 31px of total top whitespace
        margin-top: 16px;
        padding-left: 70px;
        cursor: pointer;

        @include media-breakpoint-up(lg) {
          margin-top: 0;
          margin-bottom: 8px;
          padding-left: 0;
          height: 40px;
        }

        @include media-breakpoint-up(xl) {
          height: 50px;
        }
      }

      .header-container {
        > .right {
          width: 100%;
        }

        > .left {
          display: flex;
          align-items: flex-end;
        }
      }

      .header-menu {
        > .left {

          display: flex;
          margin-bottom: -10px;
          @include media-breakpoint-up(lg) {
            padding: 0 5px;
          }

          @include media-breakpoint-up(xl) {
            padding: 0 15px;
          }

          > a {
            padding: 12px 8px 12px 10px;
            margin-right: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1px;

            &.toggled,
            &:active,
            &:hover,
            &:focus,
            &:focus-visible {
              color: $turquoise-1200;
            }

            @include media-breakpoint-up(lg) {
              margin: 0 9px;
              font-size: 16px;
            }

            @include media-breakpoint-up(xl) {
              font-size: 18px;
              margin: 0 12px;
            }

            img {
              margin-left: 0 !important;
              height: 24px !important;
              width: 24px !important;
            }
          }
        }

        > .right {
          padding: 3px 0;
          > *:not(:last-child) {
            margin-right: 24px;
          }
        }

        .search {
          padding: 11px 11px;
          border: 1px solid transparent;
          margin-bottom: -50px;
          margin-top: -5px;
          margin-left: -40px;
          margin-right: 10px !important;
          &.toggled {
            padding: 10px 11px 20px 11px;
            position: relative;
            display: inline-block;
            background-color: $gray-100;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border: 1px solid $slate-200;
            border-bottom-color: $white;
            border-bottom-width: 5px;
            &:hover,
            &:active,
            &:focus,
            &:focus-visible {
              border-bottom-color: $white;
              border-bottom-width: 5px;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
          &:hover,
          &:active,
          &:focus,
          &:focus-visible {
            cursor: pointer;
            background-color: $gray-100;
            border: 1px solid $slate-200;
            border-radius: 5px;
          }
        }

        > .navigation {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          padding: 10px 22px 9px 22px;
          margin: 0 !important;

          &:after {
            content: 'MENU';
            font-weight: $font-weight-bold;
            font-size: 12px;
            position: absolute;
            color: $turquoise-1200;
            bottom: 12px;
            left: 16px;
          }

          &.toggled {
            padding: 16px 24px 9px 20px;
            // Make the element un-clickable.
            z-index: -1;

            &:after {
              content: '';
            }

            img {
              height: 30px;
              width: 30px;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }

        > *:not(.left):not(.right):not(:last-child) {
          margin-right: 24px;
          @include media-breakpoint-up(lg) {
            margin: 0 24px;
            padding-bottom: 29px;
          }
        }

      }
    }
  }

  .secure-connection {
    padding-top: 5px;
    svg {
      height: 24px;
      margin-left: 40px;
    }
  }

  :global {
    .clearfix:after {
      content: '';
      display: table;
      clear: both;
    }

    .header-menu {
      a {
        font-size: 18px;

        &.icon-toggle.dropdown {
          position: relative;
          text-decoration: none;
          color: $slate-1200;

          &:hover,
          &:focus,
          &:focus-visible,
          &:active {
            background-color: $biscuit-100;
            text-decoration: underline;
            border-radius: 4px;
          }

          &.toggled {
            background-color: $slate-200;
            border-radius: 4px;
            text-decoration: none;
            img {
              transform: rotate(180deg);
            }
          }

          // toggle chevron icons
          img {
            color:transparent;
            margin-left: 10px;
            height: 9px;
          }
          &:hover {
            cursor: pointer;
          }
        }

        .home {
          height: 17px;
          @include media-breakpoint-up(lg) {
            height: 22px;
          }
        }
      }

      img {
        height: 25px;
      }

      // TODO: replace with index.module.scss buttons
      a.header-menu--button,
      button.header-menu--button {
        margin: 0;

        @include media-breakpoint-down(lg) {
          padding: 0;
          padding-top: 21px;
          padding-bottom: 22px;
          height: 100%;
          width: 82px;
          border-radius: 0;
          font-weight: $font-weight-bold;
          text-transform: uppercase;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .search > img {
        @include media-breakpoint-up(lg) {
          height: 24px;
          width: 24px;
          vertical-align: text-bottom;
        }
      }
    }

    .header-toplinks {
      margin-bottom: 14px;

      > mark {
        display: inline-block;

        &:not(:first-child) {
          padding-left: 10px;
        }

        &:not(:last-child) {
          padding-right: 10px;
          border-right: 1px solid $slate-200;
        }
      }
    }
  }
}

.recaptcha-icon, .ssl-icon {
  display: none;
}
@include media-breakpoint-up(lg) {
  .lock-icon {
    display: none;
  }
  .recaptcha-icon, .ssl-icon {
    display: inline;
    min-height: 32px;
  }
}
